import React, {useState} from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { auth } from "../lib/db";
import { Link, useHistory } from "react-router-dom";

import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PersonIcon from "@material-ui/icons/Person";
import PrintIcon from "@material-ui/icons/Print";
import CloudIcon from "@material-ui/icons/Cloud";
import BugIcon from "@material-ui/icons/AddAlert";
import GdprIcon from "@material-ui/icons/VerifiedUser";
import KeyIcon from "@material-ui/icons/VpnKey";

const drawerWidth = 290;

const admins = [
  "6srZnLYvQOha4Yo8yzGOCrlml783",
  "KizslfFQUneYlzASKk08i1wokjD3",
  "wEMgFssbgqZEMN2HFoW12YqPAds1",
  "cyC98elOAiVyIBd0VUhoqbmuLZk2"
];

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth
    // flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
    // marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  grow: {
    flexGrow: 1
  },
  link: {
    color: "black",
    textDecoration: "none"
  }
}));

export default function PersistentDrawerLeft(props) {
  let history = useHistory();
  const [authState, setAuthState] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  });

  const logoutHandle = () => {
    auth.signOut();
    history.push("/");
  };
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography style={{ fontSize: "1.2rem" }} variant="subtitle1" noWrap>
            addictad Print&Go
          </Typography>
          <div className={classes.grow} />

          <Button
            size="small"
            variant="contained"
            onClick={() => logoutHandle()}
            style={{ backgroundColor: "white" }}
          >
            Log out
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <div>
          <List>
            <Link className={classes.link} to="/print">
              <ListItem button>
                <ListItemIcon>
                  <PrintIcon />
                </ListItemIcon>
                <ListItemText primary="Comandă nouă" />
              </ListItem>
            </Link>

            <Link className={classes.link} to="/comenzi">
              <ListItem button>
                <ListItemIcon>
                  <CloudIcon />
                </ListItemIcon>
                <ListItemText primary="Comenzile mele" />
              </ListItem>
            </Link>

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <Link className={classes.link} to="/account">
              <ListItem button>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Contul meu" />
              </ListItem>
            </Link>

            <Link className={classes.link} to="/problema">
              <ListItem button>
                <ListItemIcon>
                  <BugIcon />
                </ListItemIcon>
                <ListItemText primary="Raportează o problemă" />
              </ListItem>
            </Link>

            <Link className={classes.link} to="/gdpr">
              <ListItem button>
                <ListItemIcon>
                  <GdprIcon />
                </ListItemIcon>
                <ListItemText primary="Protecția datelor personale" />
              </ListItem>
            </Link>

            {authState && admins.includes(
              auth.currentUser.uid) ? (
                <div>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  <Link className={classes.link} to="/admin">
                    <ListItem button>
                      <ListItemIcon>
                        <KeyIcon />
                      </ListItemIcon>
                      <ListItemText primary="Admin" />
                    </ListItem>
                  </Link>
                </div>
              ) : null            }
          </List>
          <Divider />
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
}
