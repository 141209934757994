import React from "react";
import { useState, useEffect } from "react";
import { auth, db } from "../lib/db";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SignupDivider from "./SignupDivider";

const ContactSchema = Yup.object().shape({
  displayName: Yup.string()
    .min(5, "Minimum 5 caractere")
    .max(30, "Maximum 30 caractere")
    .required("Câmp necesar"),
  email: Yup.string()
    .email("Adresă de email invalidă.")
    .required("Câmp necesar"),
  phone: Yup.string()
    .min(9, "Minimum 10 caractere")
    .max(16, "Maximum 16 caractere")
    .required("Câmp necesar")
});

const SignupFormContact = () => {
  let history = useHistory();
  const [authState, setAuthState] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  });

  const [userDetails, setUserDetails] = useState({});
  async function fetchData() {
    if (authState === true) {
      await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            setUserDetails(doc.data());
          }
        });
    } else {
      return;
    }
  }

  useEffect(() => {
    fetchData();
  }, [authState]);

  // console.log(userDetails);

  return (
    <Container style={{padding: 0}}>
      <Card>
        <CardContent>
          <Typography variant="h6">Contul meu</Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            Vă rugăm completați <strong>toate câmpurile</strong> de mai jos
            pentru a putea folosi aplicația Print&Go
          </Typography>
          {authState ? (
            <Formik
              enableReinitialize="true"
              initialValues={{
                displayName: userDetails.displayName
                  ? userDetails.displayName
                  : "",
                email: userDetails.email ? userDetails.email : "",
                phone: userDetails.phone ? userDetails.phone : ""
              }}
              onSubmit={values => {
                db.collection("users")
                  .doc(auth.currentUser.uid)
                  .update(values);
                history.push("/myhome");
              }}
              validationSchema={ContactSchema}
            >
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Numele dvs. complet"
                    fullWidth
                    id="displayName"
                    name="displayName"
                    margin="normal"
                    variant="outlined"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.displayName}
                  />
                  {props.errors.displayName && props.errors.displayName}

                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Email"
                    fullWidth
                    id="email"
                    name="email"
                    margin="normal"
                    variant="outlined"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                  />
                  {props.errors.email && props.errors.email}

                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Telefon"
                    fullWidth
                    id="phone"
                    name="phone"
                    margin="normal"
                    variant="outlined"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.phone}
                  />
                  {props.errors.phone && props.errors.phone}
                  <SignupDivider />
                  <Button
                    style={{ marginTop: "20px" }}
                    type="submit"
                    variant="contained"
                  >
                    Salvează
                  </Button>
                </form>
              )}
            </Formik>
          ) : null}
        </CardContent>
      </Card>
    </Container>
  );
};

export default SignupFormContact;
