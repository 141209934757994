import React from "react";
import moment from "moment";
import { auth, db } from "../lib/db";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles({
  orderItem: {
    marginBottom: 10
  }
});

export default function Orders() {
  const classes = useStyles();
  const [authState, setAuthState] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  });

  const [searchDone, setSearchDone] = useState(false);
  const [listaComenzi, setComenzi] = useState([]);

  async function fetchData() {
    if (authState == true) {
      let orderList = [];
      let cleanOrders = [];
      await db
        .collection("comenzi")
        .where("belongsTo", "==", auth.currentUser.uid)
        .get()
        .then(docs =>
          docs.forEach(doc => {
            orderList.push(doc.data());
          })
        );
      orderList = orderList.sort((a, b) =>
        a.orderTime < b.orderTime ? 1 : -1
      );
      orderList.forEach(order => {
        let docNamesArray = [];
        // console.log(order.fisiere);
        order.fisiere.forEach(doc => docNamesArray.push(doc.name));
        let docNames = docNamesArray.join(", ");
        let clean = {
          culoare: order.culoare === "bw" ? "alb-negru" : "color",
          fisiere: order.fisiere.length,
          copii: order.copii,
          documente: docNames,
          format: order.format.toUpperCase(),
          tip: order.tip === "f" ? "față" : "față-verso",
          orderDate: moment.unix(order.orderTime).format("DD/MM/YYYY"),
          orderTime: moment.unix(order.orderTime).format("H:mm:s"),
          stare:
            order.stare === "anulat"
              ? "anulată"
              : order.stare === "pending"
              ? "în așteptare"
              : "finalizată",
          oid: order.oid,
          shortId: order.oid.slice(0, 8).toUpperCase()
        };
        cleanOrders.push(clean);
      });
      setComenzi(cleanOrders);
      setSearchDone(true);
    }
  }
  // console.log("listaComenzi", listaComenzi);

  useEffect(() => {
    fetchData();
  }, [authState]);

  const rejectOrder = async oid => {
    // console.log(oid);
    await db
      .collection("comenzi")
      .doc(oid)
      .update({ stare: "anulat" })
      .then(() => window.location.reload());
  };

  return (
    <Container style={{ padding: 0 }}>
      <Typography variant="h6">Comenzile mele</Typography>
      <Typography style={{ marginTop: 10, marginBottom: 20 }} variant="body2">
        Comenzile mele din ultimele 30 de zile. Documentele sunt automat șterse
        după această perioadă.
      </Typography>
      <Grid
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        container
        spacing={5}
      >
        {listaComenzi.map(comanda => (
          <Grid key={comanda.oid} xl={4} lg={4} md={4} xs={12} item>
            <Card style={{ height: "100%" }}>
              <CardContent>
                <Typography className={classes.orderItem} variant="body2">
                  <strong>Data comenzii: </strong>
                  {comanda.orderDate}
                </Typography>
                <Typography className={classes.orderItem} variant="body2">
                  <strong>Ora comenzii: </strong>
                  {comanda.orderTime}
                </Typography>
                <Typography className={classes.orderItem} variant="body2">
                  <strong>Cod: </strong>
                  {comanda.shortId}
                </Typography>
                <Chip
                  label={` comandă ${comanda.stare}   `}
                  style={{
                    marginTop: 10,
                    // marginBottom: 10,
                    backgroundColor:
                      comanda.stare === "anulată"
                        ? null
                        : comanda.stare === "în așteptare"
                        ? "#ffc107"
                        : "#4caf50",
                    color:
                      comanda.stare === "anulată"
                        ? null
                        : comanda.stare === "în așteptare"
                        ? "black"
                        : "white"
                  }}
                />
                {comanda.stare === "în așteptare" ? (
                  <Chip
                    label="anulează"
                    // color="primary"
                    style={{
                      marginTop: 10,
                      marginLeft: 10
                    }}
                    onClick={() => rejectOrder(comanda.oid)}
                  />
                ) : null}
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                <Typography className={classes.orderItem} variant="body2">
                  <strong>Nr. documente: </strong>
                  {comanda.fisiere}
                </Typography>
                <Typography className={classes.orderItem} variant="body2">
                  <strong>Nr. copii: </strong>
                  {comanda.copii}
                </Typography>
                <Typography className={classes.orderItem} variant="body2">
                  <strong>Documente: </strong>
                </Typography>
                <Typography className={classes.orderItem} variant="body2">
                  {comanda.documente}
                </Typography>
                <Typography className={classes.orderItem} variant="body2">
                  <strong>Cromatică: </strong>
                  {comanda.culoare}
                </Typography>
                <Typography className={classes.orderItem} variant="body2">
                  <strong>Format: </strong>
                  {comanda.format}
                </Typography>
                <Typography className={classes.orderItem} variant="body2">
                  <strong>Tipul imprimării: </strong>
                  {comanda.tip}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
