import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Routes from "./routes/Routes";

const jsx = (
  <MuiThemeProvider theme={theme}>
    <Routes />
  </MuiThemeProvider>
);

ReactDOM.render(jsx, document.getElementById("root"));

