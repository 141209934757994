import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { auth, firebase } from "./lib/db";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage:
      "url(https://source.unsplash.com/featured/?{colorful})",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  explText: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "center",
    paddingLeft: "10%",
    paddingRight: "10%"
  },
  loginButton: {
    marginTop: 10,
    width: "200px"
  }
}));

export default function SignInSide(props) {
  const [authState, setAuthState] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  });

  let history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (authState === true) {
      history.push("/myhome");
    }
  }, [authState])


  const authenticate = provider => {
    // console.log(provider);
    const authProvider = new firebase.auth[`${provider}AuthProvider`]();
    auth.signInWithRedirect(authProvider).then(result => {
      const authUser = {
        uit: result.user.uid,
        email: result.user.email,
        name: result.user.displayName,
        username: result.user.email,
        photo: result.user.photoUrl
      };
      // console.log(authUser);
      authHandler(authUser);
    });
    const authHandler = authUser => {
      history.push("/myhome");
    };
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Grid
          container
          alignItems="center"
          component="div"
          className={classes.root}
        >
          <div className={classes.paper}>
          <img style={{width: 150, marginBottom: 0 }} src="add-logo.png" alt="" />
            <Typography component="h1" variant="h6">
              Log In - Print&Go
            </Typography>
            <Typography
              className={classes.explText}
              component="p"
              variant="body1"
            >
              Conectează-te la contul tău addictad pentru a trimite și manageria
              comenzile tale de imprimare. Dacă nu ai încă un cont, alege una
              din opțiunile de mai jos și contul tău va fi creat automat.
            </Typography>
            <Button
              onClick={() => authenticate("Google")}
              className={classes.loginButton}
              variant="contained"
              style={{backgroundColor: "#c94131", color: "white"}}
            >
              Log-in cu Google
            </Button>
            <Button
              disabled={false}
              onClick={() => authenticate("Facebook")}
              className={classes.loginButton}
              variant="contained"
              style={{backgroundColor: "#3a63bf", color: "white"}}
            >
              Log-in cu Facebook
            </Button>
            <Typography
              className={classes.explText}
              component="p"
              variant="subtitle2"
              style={{ marginTop: 20}}
            >
              Această aplicație folosește cookies. Prin continuarea navigării vă exprimați acordul privind folosirea lor.
            </Typography>
            <Chip
              // color="primary"
              icon={<InfoIcon />}
              label="Procesarea datelor cu caracter personal"
              onClick={() => window.open("/gdpr", "_blank")}
              style={{  marginBottom: 10 }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
