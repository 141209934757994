import React from "react";
import AdminDash from "./views/AdminDash";
import CanceledOrders from "./components/CanceledOrders";

export default function MyOrders() {
  return (
    <AdminDash>
      <CanceledOrders />
    </AdminDash>
  );
}
