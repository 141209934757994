import React from "react";
import AdminDash from "./views/AdminDash";
import ProblemList from "./components/ProblemList";

export default function MyOrders() {
  return (
    <AdminDash>
      <ProblemList />
    </AdminDash>
  );
}
