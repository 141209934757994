import React from "react";
import Dashboard from "./views/Dashboard";
import ProblemReport from "./components/ProblemReport";

export default function NewPrint() {
  return (
    <Dashboard>
      <ProblemReport />
    </Dashboard>
  );
}
