import React from "react";
import { useState, useEffect } from "react";
import { auth, db } from "../lib/db";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

const PrintOrder = () => {
  let history = useHistory();
  const [authState, setAuthState] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  });

  const [userDetails, setUserDetails] = useState({});
  async function fetchData() {
    if (authState == true) {
      await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            setUserDetails(doc.data());
          }
        });
    } else {
      return;
    }
  }
  useEffect(() => {
    fetchData();
  }, [authState]);

  const [value, setValue] = React.useState("");

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleSubmit = async () => {
    let newProblem = {
      problema: value,
      belongsTo: auth.currentUser.uid,
      client: auth.currentUser.displayName,
      email: userDetails.email,
      phone: userDetails.phone,
      time: moment().format('X')
    };
    await db.collection("probleme").add(newProblem);
    history.push("/problema-confirmare");
  };

  return (
    <Container style={{ padding: 0 }}>
      <Card style={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h6">Raportează o problemă</Typography>
          <Typography variant="body2">
            Ai întâmpinat o problemă? Ai o sugestie? Poți să ne trimiți un mesaj
            prin formularul de mai jos.
          </Typography>
          <TextField
            fullWidth
            id="standard-multiline-flexible"
            label="Mesaj"
            multiline
            rowsMax="4"
            value={value}
            onChange={handleChange}
            style={{ marginTop: 20, marginBottom: 20 }}
          />
          <Button
            disabled={value.length < 10}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Trimite
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PrintOrder;
