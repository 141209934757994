import React from "react";
import AdminDash from "./views/AdminDash";
import NewOrders from "./components/NewOrders";

export default function MyOrders() {
  return (
    <AdminDash>
      <NewOrders />
    </AdminDash>
  );
}
