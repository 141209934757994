import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "../App";
import MyHome from "../MyHome";
import MyAccount from "../MyAccount";
import NewPrint from "../NewPrint";
import MyOrders from "../MyOrders";
import Admin from "../Admin";
import AdminDone from "../AdminDone";
import AdminCanceled from "../AdminCanceled";
import AdminProbleme from "../AdminProbleme";
import GDPR from "../GDPR";
import Problem from "../Problem";
import ProblemConfirm from "../ProblemConfirmation";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={App} exact={true} />
        <Route path="/login" component={App} exact={true} />
        <Route path="/myhome" component={MyHome} exact={true} />
        <Route path="/account" component={MyAccount} exact={true} />
        <Route path="/print" component={NewPrint} exact={true} />
        <Route path="/comenzi" component={MyOrders} exact={true} />
        <Route path="/admin" component={Admin} exact={true} />
        <Route path="/admin-done" component={AdminDone} exact={true} />
        <Route path="/admin-anulate" component={AdminCanceled} exact={true} />
        <Route path="/admin-probleme" component={AdminProbleme} exact={true} />
        <Route path="/gdpr" component={GDPR} exact={true} />
        <Route path="/problema" component={Problem} exact={true} />
        <Route path="/problema-confirmare" component={ProblemConfirm} exact={true} />
      </Switch>
    </BrowserRouter>
  );
}
