import React from "react";
import Dashboard from "./views/Dashboard";
import PrintOrder from "./components/PrintOrder";

export default function NewPrint() {
  return (
    <Dashboard>
      <PrintOrder />
    </Dashboard>
  );
}
