import React from "react";
import moment from "moment";
import { auth, db } from "../lib/db";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles({
  orderItem: {
    marginBottom: 10
  }
});

const admins = [
  "6srZnLYvQOha4Yo8yzGOCrlml783",
  "KizslfFQUneYlzASKk08i1wokjD3",
  "wEMgFssbgqZEMN2HFoW12YqPAds1",
  "cyC98elOAiVyIBd0VUhoqbmuLZk2"
];

export default function Orders() {
  const classes = useStyles();
  const [authState, setAuthState] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  });

  const [searchDone, setSearchDone] = useState(false);
  const [listaComenzi, setComenzi] = useState([]);

  async function fetchData() {
    if (authState == true) {
      let orderList = [];
      let cleanOrders = [];
      await db
        .collection("comenzi")
        .where("stare", "==", "pending")
        .get()
        .then(docs =>
          docs.forEach(doc => {
            orderList.push(doc.data());
          })
        );
      orderList = orderList.sort((a, b) =>
        a.orderTime < b.orderTime ? 1 : -1
      );
      orderList.forEach(order => {
        let docNamesArray = [];
        // console.log(order.fisiere);
        order.fisiere.forEach(doc => docNamesArray.push(doc.name));
        let docNames = docNamesArray.join(", ");
        let clean = {
          culoare: order.culoare === "bw" ? "alb-negru" : "color",
          fisiere: order.fisiere.length,
          copii: order.copii,
          files: order.fisiere,
          documente: docNames,
          format: order.format.toUpperCase(),
          tip: order.tip === "f" ? "față" : "față-verso",
          orderDate: moment.unix(order.orderTime).format("DD/MM/YYYY"),
          orderTime: moment.unix(order.orderTime).format("H:mm:s"),
          stare:
            order.stare === "anulat"
              ? "anulată"
              : order.stare === "pending"
              ? "în așteptare"
              : "finalizată",
          oid: order.oid,
          client: order.client,
          phone: order.phone,
          email: order.email,
          shortId: order.oid.slice(0, 8).toUpperCase()
        };
        cleanOrders.push(clean);
      });
      setComenzi(cleanOrders);
      setSearchDone(true);
    }
  }
  // console.log("listaComenzi", listaComenzi);

  useEffect(() => {
    fetchData();
  }, [authState]);

  const rejectOrder = async oid => {
    // console.log(oid);
    await db
      .collection("comenzi")
      .doc(oid)
      .update({ stare: "anulat" })
      .then(() => window.location.reload());
  };
  const accceptOrder = async oid => {
    // console.log(oid);
    await db
      .collection("comenzi")
      .doc(oid)
      .update({ stare: "finalizat" })
      .then(() => window.location.reload());
  };
  return (
    <Container style={{ padding: 0 }}>
      {authState && admins.includes(auth.currentUser.uid) ? (
        <div>
          <Typography variant="h6">Comenzi în așteptare</Typography>
          <Typography
            style={{ marginTop: 10, marginBottom: 20 }}
            variant="body2"
          >
            Toate comenzile în așteptare.
          </Typography>

          {listaComenzi.length === 0 ? (
            <Typography style={{marginTop: 10, marginBottom: 10, fontWeight: 700}} variant="body2">Nici o comandă în așteptare</Typography>
          ) : null}
          <Grid
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            container
            spacing={3}
          >
            {listaComenzi.map(comanda => (
              <Grid key={comanda.oid} xl={4} lg={4} md={4} xs={12} item>
                <Card style={{ height: "100%" }}>
                  <CardContent>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Data comenzii: </strong>
                      {comanda.orderDate}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Ora comenzii: </strong>
                      {comanda.orderTime}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Cod: </strong>
                      {comanda.shortId}
                    </Typography>
                    <Chip
                      label="anulează"
                      // color="primary"
                      style={{
                        marginTop: 10
                        // marginLeft: 10
                      }}
                      onClick={() => rejectOrder(comanda.oid)}
                    />
                    <Chip
                      label="finalizează"
                      color="primary"
                      style={{
                        marginTop: 10,
                        marginLeft: 10
                      }}
                      onClick={() => accceptOrder(comanda.oid)}
                    />

                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Documente: </strong>
                    </Typography>
                    {comanda.files.map((fisier, index) => (
                      <Chip
                        avatar={<Avatar>{index + 1}</Avatar>}
                        label={fisier.name}
                        onClick={() => window.open(fisier.file, "_blank")}
                        style={{
                          marginBottom: 10,
                          marginTop: 0,
                          marginRight: 10
                        }}
                      />
                    ))}
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Nr. copii: </strong>
                      {comanda.copii}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Cromatică: </strong>
                      {comanda.culoare}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Format: </strong>
                      {comanda.format}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Tipul imprimării: </strong>
                      {comanda.tip}
                    </Typography>
                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Client: </strong>
                      {comanda.client}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Telefon: </strong>
                      {comanda.phone}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Email: </strong>
                      {comanda.email}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <Typography variant="body2">Pagina nu a fost găsită.</Typography>
      )}
    </Container>
  );
}
