import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import { useState, useEffect } from "react";
import { firebase, auth, db } from "../lib/db";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import PrintIcon from "@material-ui/icons/Print";
import ErrorIcon from "@material-ui/icons/Error";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import moment from "moment";

const uuidv1 = require("uuid/v1");

const storageRef = firebase.storage().ref();

const useStyles = makeStyles({
  dzone: {
    width: "100%",
    height: "10rem",
    backgroundColor: "#e4e4e4",
    color: "#8a8a8a",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    fontFamily: `"Segoe UI", Tahoma, Geneva, Verdana, sans-serif`,
    fontSize: "18px",
    border: "1px solid #cccccc",
    cursor: "pointer",
    marginBottom: "30px",
    marginTop: "15px"
  },
  explainText: {
    marginLeft: "20px"
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 340
  },
  media: {
    heigh: "100px",
    paddingTop: "56%" // 16:9
  },
  cardContainer: {
    marginTop: "30px"
  },
  button: {
    width: "100%",
    marginBottom: "5px",
    marginTop: "5px"
  }
});

const PrintOrder = () => {
  let history = useHistory();
  const classes = useStyles();
  const [authState, setAuthState] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  });

  const [userDetails, setUserDetails] = useState({});
  async function fetchData() {
    if (authState == true) {
      await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            setUserDetails(doc.data());
          }
        });
    } else {
      return;
    }
  }
  useEffect(() => {
    fetchData();
  }, [authState]);

  // console.log(userDetails);

  const [warningState, setWarningState] = useState(false);
  useEffect(() => {
    if (userDetails !== {}) {
      setWarningState(
        userDetails.displayName === "" ||
          userDetails.email === "" ||
          userDetails.phone === ""
      );
    }
  });

  const [uploaded, setUploaded] = useState([]);
  const [paperFormat, setPaperFormat] = useState("a4");
  const [culoare, setCuloare] = useState("bw");
  const [tipPrint, setTipPrint] = useState("f");
  const [nrPrint, setNrPrint] = useState(1);
  const [loadingState, setLoadingState] = useState(false);
  // console.log(uploaded);

  const handleFormatHartie = event => {
    setPaperFormat(event.target.value);
  };
  const handleCuloare = event => {
    setCuloare(event.target.value);
  };
  const handleTipPrint = event => {
    setTipPrint(event.target.value);
  };
  const handleNrPrint = event => {
    setNrPrint(event.target.value);
  };

  const handleSubmit = async () => {
    let newOrder = {
      fisiere: uploaded,
      format: paperFormat,
      culoare: culoare,
      copii: nrPrint,
      tip: tipPrint,
      belongsTo: auth.currentUser.uid,
      oid: uuidv1(),
      orderTime: moment().format("X"),
      stare: "pending",
      client: auth.currentUser.displayName,
      email: userDetails.email,
      phone: userDetails.phone
    };
    await db
      .collection("comenzi")
      .doc(newOrder.oid)
      .set(newOrder);
    history.push("/myhome");
  };

  return (
    <Container style={{ padding: 0 }}>
      <Card style={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h6">Comandă nouă</Typography>
          {warningState ? (
            <div>
              <Typography
                style={{ marginTop: 10 }}
                color="primary"
                variant="subtitle2"
              >
                Pentru a putea procesa comanda dvs., vă rugăm completați toate
                datele de contact.
              </Typography>

              <Chip
                color="primary"
                icon={<ErrorIcon />}
                label="Completează acum"
                onClick={() => history.push("/account")}
                style={{ marginTop: 10, marginBottom: 10 }}
              />
            </div>
          ) : null}
          <Typography
            variant="body2"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Vă rugăm adăugați documente cu dimensiunea de până la{" "}
            <strong>10MB</strong> și cu extensiile <strong>.pdf, .doc</strong>{" "}
            și <strong>.docx</strong>.
          </Typography>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <Typography
            variant="body2"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Fișiere în <strong>comanda curentă:</strong>
          </Typography>

          {uploaded.length > 0 ? (
            uploaded.map(file => (
              <Chip
                style={{ margin: 4 }}
                icon={<PrintIcon />}
                label={file.name}
                key={file.nume}
              />
            ))
          ) : loadingState ? null : (
            <Typography
              variant="body2"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              0 fișiere adăugate :(
            </Typography>
          )}

          {loadingState ? (
            <LinearProgress
              style={{ marginTop: 20, marginBottom: 20 }}
              color="primary"
            />
          ) : null}

          <Dropzone
            accept=""
            maxSize={10485760}
            onDrop={async acceptedFiles => {
              // console.log("upload");
              await acceptedFiles.forEach(file => {
                setLoadingState(true);
                storageRef
                  .child(`/${auth.currentUser.uid}/${file.name}`)
                  .put(file)
                  .then(resp => {
                    resp.ref.getDownloadURL().then(result => {
                      setLoadingState(false);
                      setUploaded(existing => [
                        ...existing,
                        { file: result, name: file.name }
                      ]);
                    });
                  });
              });
            }}
            onDropRejected={files => {
              files.forEach(file => {
                if (file.size > 10485760) {
                  alert(`Fișierul "${file.name}" depășește 10MB.`);
                }
              });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className={classes.dzone} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button color="primary" variant="contained">
                    Adaugă fișier
                  </Button>
                </div>
              </section>
            )}
          </Dropzone>
          <Grid container style={{ marginBottom: 20 }} spacing={4}>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel id="format-hartie-label">Format hârtie</InputLabel>
                <Select
                  style={{ width: "100px" }}
                  labelId="format-hartie-label"
                  id="format-hartie"
                  value={paperFormat}
                  onChange={handleFormatHartie}
                >
                  <MenuItem value={"a4"}>A4</MenuItem>
                  <MenuItem value={"a3"}>A3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel id="format-hartie-label">Culoare</InputLabel>
                <Select
                  style={{ width: "100px" }}
                  labelId="format-hartie-label"
                  id="format-hartie"
                  value={culoare}
                  onChange={handleCuloare}
                >
                  <MenuItem value={"bw"}>alb-negru</MenuItem>
                  <MenuItem value={"color"}>color</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel id="format-hartie-label">Tip imprimare</InputLabel>
                <Select
                  style={{ width: "100px" }}
                  labelId="format-hartie-label"
                  id="format-hartie"
                  value={tipPrint}
                  onChange={handleTipPrint}
                >
                  <MenuItem value={"f"}>față</MenuItem>
                  <MenuItem value={"fv"}>față/verso</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel id="exemplare-label">Nr. copii</InputLabel>
                <Select
                  style={{ width: "100px" }}
                  labelId="exemplare-label"
                  id="exemplare"
                  value={nrPrint}
                  onChange={handleNrPrint}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            style={{ marginRight: "20px" }}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            disabled={uploaded.length === 0 || warningState}
          >
            trimite comanda
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PrintOrder;
