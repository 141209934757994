import React from "react";
import AdminDash from "./views/AdminDash";
import DoneOrders from "./components/DoneOrders";

export default function MyOrders() {
  return (
    <AdminDash>
      <DoneOrders />
    </AdminDash>
  );
}
