import React from "react";
import moment from "moment";
import { auth, db } from "../lib/db";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles({
  orderItem: {
    marginBottom: 10
  }
});

const admins = [
  "6srZnLYvQOha4Yo8yzGOCrlml783",
  "KizslfFQUneYlzASKk08i1wokjD3",
  "wEMgFssbgqZEMN2HFoW12YqPAds1",
  "cyC98elOAiVyIBd0VUhoqbmuLZk2"
];

export default function Orders() {
  const classes = useStyles();
  const [authState, setAuthState] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  });

  const [problems, setProblems] = useState([]);

  async function fetchData() {
    if (authState == true) {
      let problemList = [];
      await db
        .collection("probleme")
        .get()
        .then(docs =>
          docs.forEach(doc => {
            problemList.push(doc.data());
          })
        );
      problemList = problemList.sort((a, b) => (a.time < b.time ? 1 : -1));
      setProblems(problemList);
    }
  }
  // console.log("problems", problems);

  useEffect(() => {
    fetchData();
  }, [authState]);

  return (
    <Container style={{ padding: 0 }}>
      {authState && admins.includes(auth.currentUser.uid) ? (
        <div>
          <Typography variant="h6">Probleme semnalate</Typography>
          <Typography
            style={{ marginTop: 10, marginBottom: 20 }}
            variant="body2"
          >
            Toate problemele semnalate de clienți.
          </Typography>
          <Grid
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            container
            spacing={3}
          >
            {problems.map(problema => (
              <Grid key={problema.time} xl={4} lg={4} md={4} xs={12} item>
                <Card style={{ height: "100%" }}>
                  <CardContent>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Data problemei: </strong>
                      {moment.unix(problema.time).format("DD/MM/YYYY")}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Ora problemei: </strong>
                      {moment.unix(problema.time).format("H:mm:s")}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Mesaj: </strong>
                      {problema.problema}
                    </Typography>

                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Client: </strong>
                      {problema.client}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Telefon: </strong>
                      {problema.phone}
                    </Typography>
                    <Typography className={classes.orderItem} variant="body2">
                      <strong>Email: </strong>
                      {problema.email}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <Typography variant="body2">Pagina nu a fost găsită.</Typography>
      )}
    </Container>
  );
}
