import React from "react";
import Dashboard from "./views/Dashboard";
import FormContact from "./components/FormContact";

export default function MyAccount() {
  return (
    <Dashboard>
      <FormContact></FormContact>
    </Dashboard>
  );
}
