import React from "react";
import Dashboard from "./views/Dashboard";
import Orders from "./components/Orders";

export default function MyOrders() {
  return (
    <Dashboard>
      <Orders />
    </Dashboard>
  );
}
