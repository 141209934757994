import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { useHistory } from "react-router-dom";

export default function PrivacyPolicy() {
  let history = useHistory();
  return (
    <Container
      style={{ padding: 16 }}
      justify="center"
      alignItems="center"
      component="div"
      maxWidth="md"
    >
      <Paper style={{ padding: 16, marginTop: 0 }}>
        <Typography style={{ marginBottom: 10 }} variant="h5" component="h3">
          Informații despre procesarea datelor cu caracter personal (GDPR)
        </Typography>
        <Chip
          label="Înapoi la aplicație"
          color="primary"
          style={{
            marginTop: 10,
            marginBottom: 20
          }}
          onClick={() => history.push("/")}
        />
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          Confidențialitatea datelor dumneavoastră reprezintă o prioritate
          pentru noi, ADDICTAD SRL, în calitate de operator de date. Această
          pagină are rolul de a vă informa cu privire la prelucrarea datelor cu
          caracter personal în contextul utilizării aplicației addictad
          Print&Go, precum și în legătură cu utilizarea de fișiere tip cookie
          pentru furnizarea serviciului.
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          1. Datele cu caracter personal prelucrate sunt:
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          - nume, prenume
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          - adresă de email
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          - telefon
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          - orice date conținute în documentele adăugate pentru imprimare
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          2. Scopul prelucrării datelor este preluarea și validarea comenzilor
          plasate și pentru autentificarea în aplicația print and go.
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          3. Datele cu caracter personal menționate mai sus sunt stocate pe
          toată durata existenței contului dvs, cu excepția documentelor
          adăugate pentru imprimare, care sunt stocate pentru maxim 30 de zile
          de la data adăugării.
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          4. Acest site folosește fișiere tip cookie. Cookie-urile sunt
          utilizate pentru a furniza utilizatorilor acestei pagini de internet o
          experiență mai bună de navigare. Anumite funcționalități ale
          aplicației sunt furnizate prin servicii oferite de terți care folosesc
          propriile cookie-uri, („third party cookies”). Terții furnizori de
          cookie-uri trebuie să respecte, de asemenea, legislația în materie de
          protecție a datelor cu caracter personal.
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          Aplicația addictad Print&Go folosește cookie-uri și alte metode de
          tracking de la urmatorii terți:
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          -{" "}
          <a
            href="https://policies.google.com/technologies/cookies?hl=ro"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google - Google Analytics și servicii Google de log-in
          </a>
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          -{" "}
          <a
            href="https://www.facebook.com/about/privacy/update"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook - Facebook Social Log-in
          </a>
        </Typography>
        <Typography style={{ marginBottom: 10 }} variant="body2" component="p">
          5. Puteți solicita ștergea sau modificarea datelor cu caracter
          personal, oricând, printr-un email la{" "}
          <a
            href="mailto:gdpr@addictad.ro"
            rel="noopener noreferrer"
            target="_blank"
          >
            gdpr@addictad.ro
          </a>
        </Typography>
      </Paper>
    </Container>
  );
}
