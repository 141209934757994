import React from "react";
import { useState } from "react";
import { auth } from "../lib/db";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const PrintOrder = () => {
  let history = useHistory();
  const [authState, setAuthState] = useState(false);
  auth.onAuthStateChanged(function(user) {
    if (user) {
      setAuthState(true);
    } else {
      setAuthState(false);
    }
  });

  return (
    <Container style={{ padding: 0 }}>
      <Card style={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h6">Mesaj primit</Typography>
          <Typography style={{ marginTop: 10 }} variant="body2">
            Am primit mesajul dvs. și vom încerca să rezolvăm problema în cel
            mai scurt timp. Vă mulțumim din nou că folosiți aplicația addictad
            Print&Go.
          </Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button
              style={{ marginTop: 20 }}
              color="primary"
              variant="contained"
            >
              Comenzile mele
            </Button>
          </Link>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PrintOrder;
