import React from "react";
import Dashboard from "./views/Dashboard";
import ProblemConfirm from "./components/ProblemConfirm";

export default function NewPrint() {
  return (
    <Dashboard>
      <ProblemConfirm />
    </Dashboard>
  );
}
